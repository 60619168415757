/* Collapse area that appears underneath each category */
/* Important for width to match with category width and border radius values change when collapsing */
.expense-collapse {
    width: 90%;
    text-align: center;
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 2px 2px 2px var(--secondary1);
    color: var(--text-primary);
}

.expense-table-area {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.expense-area-drag {
    background-color: var(--secondary2-transparent)
}

.expense-header {
    height: 34px;
    align-items: center;
    background-color: var(--secondary1);
    color: var(--text-primary);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.expense-row {
    min-height: 34px;
    color: #000;
    /* background-color: var(--text-secondary); */
}

.expense-row:hover {
    background-color: var(--primary2-transparent);
}

.expense-row-input {
    height: 34px;
    background-color: var(--text-secondary);
}

.expense-row-moving {
    background-color: var(--primary2);
    color: var(--text-primary);
}

.expense-description {
    align-self: center;
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.expense-drag-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary1);
    border: 1px solid rgb(222, 226, 230);
}

.expense-drag-icon:hover {
    background-color: var(--secondary1);
    cursor: grab;
}

.expense-btn {
    width: 30px;
    height: 30px;
    padding: 0 !important;
    opacity: 0.3;
}

.expense-row:hover .expense-btn {
    opacity: 1;
}

.expense-edit-icons {
    opacity: 0;
}

.expense-row:hover .expense-edit-icons {
    opacity: 1;
    cursor: pointer;
}

.expense-edit-icon {
    font-size: 14px;
    opacity: 0.5;
}

.expense-edit-icon:hover {
    opacity: 1;
}

.expense-add-btn {
    width: 100%;
    margin-top: 0;
    padding: 4px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.expense-input-btn {
    width: 50%;
    font-size: 16px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.expense-modal-header {
    background-color: var(--primary1);
}

.expense-modal-body {
    background-color: var(--secondary2-transparent) !important;
}

.expense-modal-footer {
    background-color: var(--secondary2-transparent) !important;
}

.expense-delete-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media all and (max-width: 768px) {
    .expense-collapse {
        width: 95%;
    }

    .expense-name {
        width: 200px !important;
    }

    .expense-edit-icons {
        opacity: 0.5 !important;
    }
}

@media all and (max-width: 576px) {
    .expense-collapse {
        width: 100%;
    }

    .expense-name {
        width: 90px !important;
    }

    .expense-input-btn {
        padding: 0 !important;
    }

    .expense-btn {
        font-size: 12px !important;
    }
}