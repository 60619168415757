/* CSS Reset */

/* Box sizing rules */
*,
*::before,
*::after {
    -webkit-box-sizing:border-box !important;
    -moz-box-sizing:border-box !important;
    -ms-box-sizing:border-box !important;
    -o-box-sizing:border-box !important;
    box-sizing:border-box !important;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0 !important;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0 !important;
    padding: 0 !important;
    font: 16px Arial, sans-serif;
}

/* Set core body defaults */
body {
    min-height: 100vh !important;
    scroll-behavior: smooth !important;
    text-rendering: optimizeSpeed !important;
    line-height: 1.5 !important;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none !important;
}

/* Anchor elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto !important;
}

/* Make images easier to work with */
img {
    max-width: 100% !important;
    display: block !important;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em !important;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit !important;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}