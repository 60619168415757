.page-container {
    min-height: 90vh;
    padding-top: 1px !important;
    padding-bottom: 10px !important;
    background-color: var(--secondary1-transparent);
}

.landing-container {
    min-height: 90vh;
    background-color: var(--secondary1-transparent);
}

.landing-img {
    height: 100%;
    margin: auto;
    border-radius: 10px;
}

.landing-top {
    padding: 0;
    height: 90vh;
    align-items: center;
    background-color: var(--primary1);
    color: #fff;
}

.landing-img-top {
    box-shadow: 20px 20px 5px var(--primary2);
}

.landing-why {
    padding: 0;
    height: 90vh;
    align-items: center;
    background-color: var(--secondary1);
}

.landing-img-why {
    box-shadow: 20px 20px 5px var(--secondary2);
}

.landing-img-slides {
    box-shadow: 20px 20px 5px var(--secondary1);
}

.landing-icon {
    color: var(--primary1);
    font-size: 60px;
}

.landing-slides {
    padding: 0;
    height: 90vh;
    align-items: center;
}

.landing-footer {
    padding: 0;
    height: 30vh;
    align-items: center;
    background-color: var(--primary1);
    color: #fff;
}

.login-main {
    height: 90vh;
    align-items: center;
}

.login-card {
    margin-left: -100px;
    width: 100%;
    height: 100% !important;
    color: var(--text-primary);
    box-shadow: 50px 20px 5px var(--primary2);
    transition: 0.5s ease-in-out;
}

.login-header {
    background-color: var(--secondary1) !important;
}

.login-body {
    background-color: var(--secondary2-transparent) !important;
}

.login-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary1) !important;
}

.register-card {
    margin-left: 100px;
    width: 100%;
    height: 100% !important;
    box-shadow: -50px 20px 5px var(--secondary1);
    transition: 0.5s ease-in-out;
}

.register-header {
    color: var(--text-secondary) !important;
    background-color: var(--primary1) !important;
}

.register-body {
    background-color: var(--primary2-transparent) !important;
}

.register-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text-secondary) !important; 
    background-color: var(--primary1) !important;
}

.pasword-info-icon:hover {
    cursor: pointer;
}

.settings-card {
    width: 100%;
    height: 100% !important;
    color: var(--text-primary);
    box-shadow: 5px 5px 5px var(--primary2);
    transition: 0.5s ease-in-out;
}

.settings-header {
    background-color: var(--secondary1) !important;
    color: var(--text-secondary) !important;
}

@media all and (max-width: 768px) {
    .landing-img {
        height: 70%;
        margin-top: 20px;
    }
}

@media all and (max-width: 576px) {
    .login-card {
        margin-left: 0;
        box-shadow: 20px 20px 5px var(--primary2);
        transition: 0.5s ease-in-out;
    }

    .register-card {
        margin-left: 0;
        box-shadow: -20px 20px 5px var(--secondary1);
        transition: 0.5s ease-in-out;
    }

    .landing-img {
        height: 80%;
        margin-top: 20px;
    }
}
