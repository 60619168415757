.navbar {
    min-height: 10vh;
    background-color: var(--primary1);
}

.navbar-brand {
    text-decoration: none;
    color: var(--text-primary) !important;
    font-weight: bold;

}

.navbar-brand:hover {
    color: var(--secondary1) !important;
    font-weight: bold;
}

.navbar-link-container {
    width: 150px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.navbar-link {
    text-decoration: none;
    color: var(--text-primary) !important;
    font-weight: 400;
    font-size: large;
}

.navbar-link:hover {
    font-weight: bold;
    color: var(--secondary1) !important;
}

.dropdown-menu {
    background-color: var(--primary1) !important;
    border: 1px solid var(--text-primary) !important;
}

.dropdown-toggle {
    color: var(--text-primary) !important;
}

.dropdown-toggle:hover {
    text-decoration: none;
    font-weight: bold;
    color: var(--secondary1) !important;
}

.dropdown-divider {
    background-color: var(--text-primary) !important;
}

.navbar-link-active {
    color: var(--secondary1) !important;
    font-weight: bolder;
    border-bottom: 2px solid var(--secondary1);
}

.navbar-dropdown-container {
    max-width: 200px ;
    padding: 5px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.navbar-display-name {
    color: var(--text-primary) !important;
    font-weight: 200 !important;
}
