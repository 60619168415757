.budget-area {
    padding-top: 1px;
    min-height: 90vh;
    background-color: var(--secondary1-transparent);
}

.budget-toolbar {
    min-height: 70px !important;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--text-secondary);
    background-color: var(--secondary1) !important;
    border-radius: 5px;
}

.budget-card {
    height: 250px !important;
    box-shadow: 10px 10px var(--secondary1);
    transition: 0.1s ease-in-out;
    text-decoration: none !important;
}

.budget-card:hover {
    transform: translate(-5px, -5px);
    box-shadow: 20px 20px var(--primary2);
    transition: 0.1s ease-in-out;
}

.budget-card-header {
    background-color: var(--primary1) !important;
    color: var(--text-primary) !important;
}

.budget-card-title {
    text-align: center;
    padding: 1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.budget-card-body {
    cursor: pointer;
}

.budget-card-details {
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.budget-card-btn {
    width: 30%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.budget-create-modal-header {
    background-color: var(--primary1);
    color: var(--text-secondary) !important;
}

.budget-create-modal-body {
    background-color: var(--secondary1-transparent) !important;
}

.budget-create-modal-footer {
    background-color: var(--secondary1-transparent) !important;
}

.budget-delete-modal-header {
    background-color: var(--primary1);
    color: var(--text-secondary) !important;
}

.budget-delete-modal-body {
    background-color: var(--secondary1-transparent) !important;
}

.budget-delete-modal-footer {
    background-color: var(--secondary1-transparent) !important;
}

.budget-delete-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
