.category-area {
    padding-top: 1px;
    padding-left: 0;
    padding-right: 0;
    min-height: 90vh;
}

.category {
    margin: auto !important;
    margin-top: 10px !important;
    width: 90%;
    min-height: 60px;
    text-align: center;
    align-items: center;
    border-radius: 5px;
}

.category-add-new-btn {
    width: 100%;
    height: 60px;
    font-weight: bold !important;
}

.categories-header {
    height: 60px;
    background-color: var(--secondary1);
    color: #fff;
}

.categories-header > div > h3 {
    font-weight: bold;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-row {
    background-color: var(--text-secondary);
    border-radius: 5px;
    color: var(--text-primary);
    transition: 100ms ease-in-out;
}

.category-row:hover {
    background-color: var(--primary1-transparent);
    box-shadow: 2px 2px 2px var(--secondary1);
    cursor: pointer;
}

.category-row-editing {
    background-color: var(--primary1-transparent) !important;
    box-shadow: 2px 2px 2px var(--secondary1);
}

.category-row-open {
    background-color: var(--primary1-transparent) !important;
    box-shadow: 2px 2px 2px var(--secondary1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.category-color-icon {
    stroke: var(--text-primary);
    stroke-width: 10px;
}

.category-color-icon:hover {
    cursor: pointer;
}

.category-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.category-drag-icon {
    display: flex;
    height: 60px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    align-items: center;
    justify-content: center;
    color: var(--secondary1);
}

.category-drag-icon:hover {
    color: var(--secondary2);
    background-color: var(--secondary1);
    cursor: grab;
}

.category-btn-group {
    opacity: 0;
}

.category-row:hover .category-btn-group {
    opacity: 1;
}

.category-row-open .category-btn-group {
    opacity: 1;
}

.category-btn {
    width: 30px;
    height: 30px;
    padding: 0 !important;
}

.category-modal-header {
    background-color: var(--primary1);
}

.category-modal-body {
    background-color: var(--secondary2-transparent) !important;
}

.category-modal-footer {
    background-color: var(--secondary2-transparent) !important;
}

.category-delete-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.form-switch .form-check-input {
    width: 4em !important;
    height: 2em !important;
}

@media all and (max-width: 768px) {
    .category {
        width: 95%;
    }
}

@media all and (max-width: 576px) {
    .category {
        width: 100%;
    }

    .category-btn-group {
        margin-right: 10px;
    }

    .category-btn-col {
        display: none;
    }

    .category-row-open .category-btn-col {
        display: inline-block;
    }

    .category-row-open .category-total-col {
        display: none !important;
    }
}
