.summary-title {
    height: 60px;
    font-weight: bold;
    background-color: var(--secondary1) !important;
    color: #fff !important;
}

.summary-list > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.summary-details {
    overflow: visible;
    white-space: normal;
}